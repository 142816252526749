import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { useParams } from 'react-router-dom'

export const useOrgNodes = () => {
  const { org } = useParams()
  return useQuery({
    queryKey: ['nodes'],
    queryFn: () => UserService.listAllOrgNodes(org!),
    staleTime: Infinity,
    enabled: !!org,
  })
}
