import React, { FC } from 'react'
import { ClockIcon } from '@heroicons/react/24/outline'
import UserImageWrapper from '../user-image-wrapper/UserImageWrapper'
import { relativeDueTime } from '../../utils/dateUtils'
import dayjs from 'dayjs'
import { mapPriority } from '../../utils/mappers'
import { Base64SVG, DataField, Tooltip } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { useGroups } from '../../repositories/user/use-groups.repository'
import { useOrgNodes } from '../../repositories/user/use-orgNodes.repository'

const ItemListWidget: FC<{
  items: any[]
  title: string
  processes?: boolean
  onItemClick?(id: string): void
  onSeeMoreClick?(): void
}> = ({ items, title, processes, onItemClick, onSeeMoreClick }) => {
  const { t } = useTranslation()
  const { data: groups, isPending: fetchingGroups } = useGroups()
  const { data: nodes, isPending: fetchingNodes } = useOrgNodes()

  return (
    <>
      <header className='flex items-center justify-between'>
        <p className='text-slate-500 font-medium'>{title}</p>
        <a onClick={onSeeMoreClick} className='text-sky-500 cursor-pointer'>
          {t('Dashboard.seeMore')}
        </a>
      </header>
      <table className='w-full mt-8 table-auto'>
        <tbody>
          {items.map((item) => {
            const dueTime = relativeDueTime(item.dueDate ?? undefined)
            const priority = mapPriority(item.priority)
            return (
              <tr
                className='border-b border-slate-200 hover:bg-blue-50 select-none cursor-pointer'
                onClick={() => onItemClick?.(item.id)}
                key={item.id}
              >
                <td className='py-2 pl-3 w-fit max-w-26'>
                  <div className='flex items-center gap-x-2 w-fit'>
                    <div className='rounded-md w-5 h-5' style={{ backgroundColor: item.processDefinitionColor }}></div>
                    <p>{item.processInstanceCode}</p>
                  </div>
                </td>
                {!processes && !fetchingGroups && !fetchingNodes ? (
                  <>
                    <td className='py-2 px-3 text-center !w-16'>
                      {item.assigneeId && item.assigneeType.includes('User') && (
                        <UserImageWrapper userId={item.assigneeId} size={76} />
                      )}
                      {/* {item.assigneeId && item.assigneeType.includes('Group') && (
                        <Tooltip text={groups.results.find((group) => group.id === item.assigneeId)?.name}>
                          <div className='flex items-center gap-x-2'>
                            <Base64SVG
                              base64image={groups.results.find((group) => group.id === item.assigneeId)?.icon ?? ''}
                              className='&>svg:w-4 &>svg:h-4'
                            />
                          </div>
                        </Tooltip>
                      )} */}
                      {item.assigneeId && item.assigneeType.includes('Group') && (
                        <Tooltip text={groups?.results?.find((group) => group.id === item.assigneeId)?.name}>
                          <div className='flex items-center gap-x-2'>
                            <Base64SVG
                              base64image={groups?.results?.find((group) => group?.id === item.assigneeId)?.icon ?? ''}
                              className='&>svg:w-4 &>svg:h-4'
                            />
                          </div>
                        </Tooltip>
                      )}
                      {item.assigneeId && item.assigneeType.includes('Node') && (
                        <Tooltip text={nodes?.results?.find((node) => node.id === item.assigneeId)?.name}>
                          <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                            <p>
                              {nodes?.results
                                ?.find((node) => node.id === item.assigneeId)
                                ?.name.charAt(0)
                                .toUpperCase()}
                            </p>
                          </div>
                        </Tooltip>
                      )}
                    </td>
                    <td className='py-2 px-3'>
                      <DataField field={{ type: 'priority', value: priority }} />
                    </td>
                  </>
                ) : (
                  <td className='text-slate-400 max-w-48 whitespace-nowrap overflow-hidden text-ellipsis w-fit'>
                    {item?.title}
                  </td>
                )}
                <td className='py-2 px-3'>
                  <p className='bg-[#DEE1FC] text-slate-500 px-2 rounded-md max-w-52 whitespace-nowrap overflow-hidden text-ellipsis w-fit'>
                    {item.name}
                  </p>
                </td>
                <td className='py-2 text-center px-3'>
                  <div
                    className={`ml-auto flex items-center gap-x-2 ${
                      dayjs().isAfter(item.dueDate) ? 'text-red-500' : 'text-gray-500'
                    }`}
                  >
                    <ClockIcon className='w-5' />
                    {dueTime !== '' && <p>{relativeDueTime(item.dueDate ?? undefined)}</p>}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default ItemListWidget
