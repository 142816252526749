import React, { FC, useEffect, useMemo, useState } from 'react'
import DashboardHeader from './DashboardHeader'
import ItemListWidget from './ItemListWidget'
import SimpleWidget from './SimpleWidget'
import WidgetWrapper from './WidgetWrapper'
import OrdersProcessIcon from '../../assets/icons/OrdersProcessIcon'
import { useTasksPagination } from '../../repositories'
import dayjs from 'dayjs'
import { useTaskTimeSeries } from '../../repositories/analytics/task-time-series.repository'
import { useNavigate, useParams } from 'react-router-dom'
import { useSideMenu } from '../../repositories/process-instances/side-menu.repository'
import OpenTasksProgress from './OpenTasksProgress'
import SuccessWidget from './SuccessWidget'
import { useTranslation } from 'react-i18next'
import InfoWidget from './InfoWidget'
import { useGetApplications } from '../../repositories/user/user-applications.repository'
import { SpinnerIcon } from '@sistemiv/s-components'
import { useResponsive } from '../../context/ResponsiveContextProvider'

const Home: FC = () => {
  const { org } = useParams()
  const navigate = useNavigate()
  const { data: applications } = useGetApplications()
  const runSideMenu = !!applications
    ?.find((app) => app.code === 's-process')
    ?.permissions?.find((perm) => perm === 'manage')
  const { data: sideMenu } = useSideMenu({ enabled: runSideMenu })
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('7')
  const { data: tasks, isLoading: isTasksLoading } = useTasksPagination({ sort: 'asc', order: 'dueDate', size: 6 })
  const now = useMemo(() => dayjs(), [])
  const { t } = useTranslation()
  const { data: taskTimeSeries, isLoading: isTaskTimeSeriesLoading } = useTaskTimeSeries({
    organization: org,
    startDate: now.subtract(parseInt(selectedTimeFilter), 'days').toDate(),
  })
  const tasksPage = tasks?.pages.at(0)
  const { isMobile } = useResponsive()

  useEffect(() => {
    if (isMobile && org) navigate(`/${org}/tasks`)
  }, [isMobile, navigate, org])

  return (
    <div className='px-6 h-full overflow-auto bg-slate-200'>
      <DashboardHeader
        showCreate={runSideMenu}
        showHeaderButtons={
          (runSideMenu && sideMenu?.length > 0) || !!(taskTimeSeries?.myCompleteTask || taskTimeSeries?.myTasksTotal)
        }
        selectedTimeFilter={selectedTimeFilter}
        setSelectedTimeFilter={setSelectedTimeFilter}
      />
      {isTaskTimeSeriesLoading || isTasksLoading ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (runSideMenu && sideMenu?.length > 0) || !runSideMenu ? (
        <div className='grid grid-cols-1 gap-y-12 2xl:grid-cols-2 2xl:gap-x-12'>
          <div className='flex flex-col gap-y-8'>
            {tasksPage?.results.length > 0 ? (
              <>
                <div className='grid grid-cols-3 gap-x-8'>
                  {/* <div className='grid grid-cols-2'> */}
                  <SimpleWidget
                    className='text-white bg-[#42477A] rounded-r-none mr-[-2rem]'
                    label={t('Dashboard.assignedTasks')}
                    value={taskTimeSeries?.myOpenTasks ?? 0}
                  />

                  <SimpleWidget
                    className='text-white bg-[#42477A] rounded-l-none'
                    label={t('Dashboard.unclaimedTasks')}
                    value={taskTimeSeries?.visibleOpenTasks ?? 0}
                  />
                  {/* </div> */}
                  <SimpleWidget
                    className='text-slate-500 bg-white'
                    label={t('Dashboard.overdueTasks')}
                    value={taskTimeSeries?.overdueTasks ?? 0}
                  />
                </div>
                <WidgetWrapper className='bg-white'>
                  <ItemListWidget
                    items={tasksPage?.results ?? []}
                    title={t('Dashboard.openTasks')}
                    onSeeMoreClick={() => navigate(`/${org}/tasks`)}
                    onItemClick={(id) => navigate({ pathname: 'tasks', search: `?id=${id}` })}
                  />
                </WidgetWrapper>
              </>
            ) : taskTimeSeries?.myCompletedTask || taskTimeSeries?.myTasksTotal ? (
              <SuccessWidget title={t('Dashboard.openTasks')} message={t('Dashboard.allTasksCompleted')} />
            ) : (
              <InfoWidget title={t('Dashboard.openTasks')} message={t('Dashboard.noOpenTasks')} />
            )}
          </div>
          {!!(taskTimeSeries?.myCompleteTask || taskTimeSeries?.myTasksTotal) && (
            <div className='flex flex-col gap-y-[1px]'>
              <WidgetWrapper className='bg-white rounded-b-none'>
                <div className='grid grid-cols-2 gap-x-8 bg-white text-slate-500'>
                  <SimpleWidget
                    className='py-0'
                    label={t('Dashboard.myCompletedTasks')}
                    value={taskTimeSeries?.myCompletedTasks}
                    trend={taskTimeSeries?.completedTasksChangeRate}
                  />
                  <SimpleWidget
                    className='py-0'
                    label={t('Dashboard.myTotalTasks')}
                    value={taskTimeSeries?.myTasksTotal}
                    trend={taskTimeSeries?.totalTasksChangeRate}
                  />
                </div>
              </WidgetWrapper>
              <WidgetWrapper className='bg-white rounded-t-none grow'>
                <OpenTasksProgress
                  percentage={
                    taskTimeSeries && taskTimeSeries.myTasksTotal
                      ? (taskTimeSeries.myCompletedTasks / taskTimeSeries.myTasksTotal) * 100
                      : 0
                  }
                />
              </WidgetWrapper>
            </div>
          )}
        </div>
      ) : (
        <div className='flex flex-col items-center gap-y-4 text-slate-400 mt-20'>
          <OrdersProcessIcon className='w-12 h-12 fill-gray-400' />
          <p>{t('Dashboard.noProcesses')}</p>
        </div>
      )}
    </div>
  )
}

export default Home
