import { ProcesInstanceActions, ProcessField } from '../models/ProcessInstance'
import { AxiosResponse } from 'axios'
import { ProcessSchemaSideMenu } from '../models/ProcessSchema'
import axiosInstance, { axiosAnalyticsInstance, axiosMgmtInstance } from './http-common'
import http, { axiosBoardInstance } from './http-common'

export type Edit = 'bulk-priority' | 'bulk-due-date' | 'bulk-assignee'

class ProcessInstancesService {
  async list(organization: string, processKey: string, page: number, size: number, params?: any) {
    return (
      await http.get(`${organization}/instances/processes/key/${processKey}`, {
        params: {
          page: page - 1,
          size,
          ...params,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  // Get processInstances (all)
  async listAllInstances(organization: string, processKey: string) {
    const instances = await this.list(organization, processKey, 1, 1).then((res) => {
      return res
    })
    if (instances.total <= 1) return instances
    return this.list(organization, processKey, 1, instances.total)
  }

  async listInstance(organization: string, processInstanceId: string) {
    return (await http.get(`${organization}/instances/processes/${processInstanceId}`)).data
  }

  async getInstanceHistory(organization: string, processInstanceId: string, direction: string) {
    return (
      await axiosAnalyticsInstance.get(`${organization}/history/process-instances/${processInstanceId}`, {
        params: { direction: direction },
      })
    ).data
  }

  async getInstanceVariablesHistory(
    organization: string,
    processInstanceId: string,
    activityInstanceId: string,
    direction: string,
  ) {
    return (
      await axiosAnalyticsInstance.get(
        `/${organization}/history/process-instances/${processInstanceId}/${activityInstanceId}/variables`,
        {
          params: { direction: direction },
        },
      )
    ).data
  }

  async getInstanceVariables(organization: string, processInstanceId: string) {
    return (await axiosInstance.get(`${organization}/instances/processes/${processInstanceId}/variables`)).data
  }

  async createInstance(organization: string, type: string, payload: any) {
    return (await http.post(`/${organization}/instances/processes/key/${type}/start`, payload)).data
  }

  async setProcessInstancePriority(organization: string, processInstanceId: string, payload: { priority: number }) {
    return (await http.post(`/${organization}/instances/processes/${processInstanceId}/priority`, payload)).data
  }

  async setProcessInstanceDueDate(organization: string, processInstanceId: string, payload: { dueDate: Date }) {
    return (await http.post(`/${organization}/instances/processes/${processInstanceId}/due-date`, payload)).data
  }

  async completeTask(organization: string, id: string) {
    return (await http.post(`/${organization}/instances/user-tasks/${id}/force-complete`, {})).data
  }

  async taskRetry(id: string, organization: string) {
    return (await http.post(`${organization}/instances/service-tasks/${id}/retry`)).data
  }

  async getStartFrom(organization: string, key: string, version?: number) {
    return (await http.get(`/${organization}/forms/processes/key/${key}`, { params: { version } })).data
  }

  async getBulkActions(organization: string, key: string, payload: any): Promise<ProcesInstanceActions[]> {
    return (await http.post(`/${organization}/instances/processes/key/${key}/actions`, payload)).data
  }

  async listSideMenuItems(organization: string): Promise<ProcessSchemaSideMenu[]> {
    return (await axiosBoardInstance.get(`/${organization}/side-menu`)).data
  }

  async getProcessDiagram(organization, definitionId, processInstanceId?: string) {
    return (
      await axiosInstance.get(`${organization}/diagrams/processes/${definitionId}`, {
        params: { processInstanceId },
      })
    ).data
  }

  async getProcessFields(
    organization: string,
    key: string,
    params: { scope?: 'global' | 'activity' | 'variable'; visibleOnly?: boolean; hierarchical?: boolean },
  ): Promise<ProcessField[]> {
    return (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/fields`, { params })).data
  }

  // MOVE OTHER FROM ProcessDefinitionsSettings ALSO (process-items/)
  async changeProcessCurrentVersion(organization, processId, versionId) {
    return (await axiosMgmtInstance.post(`${organization}/process-items/${processId}/current/${versionId}`)).data
  }

  //delete process category
  async deleteProcessCategory(organization: string, id: string) {
    return (await axiosMgmtInstance.delete(`/${organization}/process-items/${id}`)).data
  }

  // addToQueryRepository
  async bulkEdit(org: string, option: string, userTaskId: string, action: string, body: any) {
    return (await http.post(`/${org}/instances/${option}/${userTaskId}/${action}`, body)).data
  }

  async uploadFile(org: string, files: File[]) {
    const formData = new FormData()
    for (const file of files) {
      formData.append('file', file)
    }
    return (await http.post(`/${org}/files`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data
  }

  async deleteFile(org: string, id: string) {
    return (await http.delete(`/${org}/files/${id}`)).data
  }

  async downloadFile(org: string, id: string) {
    return (await http.get(`/${org}/files/${id}`, {})).data
  }

  async downloadArrayBufferFile(org: string, id: string) {
    try {
      const response = await this.downloadBlobFile(org, id)
      const result = await response.blob.arrayBuffer()
      return result
    } catch (error) {
      console.error('Error downloading file:', error)
      throw error
    }
  }

  async downloadBlobFile(
    org: string,
    id: string,
    fileName?: string,
  ): Promise<{ blob: Blob; fileName: string; metadata: any }> {
    try {
      const response: AxiosResponse = await http.get(`/${org}/files/${id}`, {
        responseType: 'blob',
      })

      const metadata = response.headers

      return {
        blob: response.data,
        fileName: fileName || '',
        metadata: metadata,
      }
    } catch (error) {
      console.error('Error downloading file:', error)
      throw error
    }
  }
}
export default new ProcessInstancesService()
