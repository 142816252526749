import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { useParams } from 'react-router-dom'

export const useGroups = () => {
  const { org } = useParams()
  return useQuery({
    queryKey: ['groups'],
    queryFn: () => UserService.listAllGroups(org!),
    staleTime: Infinity,
    enabled: !!org,
  })
}
