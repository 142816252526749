import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useProcessInstanceVariablesHistory = (activityInstanceId: string, direction: string, enabled: boolean) => {
  const { org, id } = useParams()
  return useQuery<any>({
    queryKey: ['process-instance-variables-history', org, id, activityInstanceId, direction],
    queryFn: () => ProcessInstancesService.getInstanceVariablesHistory(org!, id!, activityInstanceId, direction),
    enabled: enabled && !!org && !!id,
    staleTime: 0,
  })
}
